import { ApiResponse } from '../models/ApiResponse';
import { ModuleTemplate, ModuleTemplateForm, ModuleType } from '../models/Module';
import { MoveTemplateFormInModulesRequest } from '../models/MoveTemplateFormInModulesRequest';
import TemplateModuleServiceBase from './TemplateModuleServiceBase';

export default class TemplateModuleService extends TemplateModuleServiceBase {
  // #region Builder API

  public static createTemplate(data: ModuleTemplate): Promise<ApiResponse<ModuleTemplate>> {
    return this.create('/v1/template/modules', data);
  }

  public static updateTemplate(moduleId: string, data: ModuleTemplate): Promise<ApiResponse<ModuleTemplate>> {
    return this.update(`/v1/template/modules/${moduleId}`, data);
  }

  public static getTemplates(isLocked?: boolean, isEnabled?: boolean, types?: ModuleType[]): Promise<ApiResponse<ModuleTemplate[]>> {
    return this.getAll({ url: '/v1/template/modules', clientId: undefined, isLocked, isEnabled, types });
  }

  public static getTemplate(moduleId: string, version: number | undefined = undefined): Promise<ApiResponse<ModuleTemplate>> {
    return this.getById(`/v1/template/modules/${moduleId}`, version);
  }

  public static sortOrder(moduleIds: string[]): Promise<ApiResponse<boolean>> {
    return this.sort('/v1/template/modules/sort', moduleIds);
  }

  public static getTemplateForms(moduleId: string, moduleSectionId: string | undefined = undefined): Promise<ApiResponse<ModuleTemplateForm[]>> {
    return this.getForms(`/v1/template/modules/${moduleId}/forms`, moduleSectionId);
  }

  public static removeTemplate(moduleId: string): Promise<ApiResponse<boolean>> {
    return this.remove(`/v1/template/modules/${moduleId}`);
  }

  public static lockTemplate(moduleId: string, data?: ModuleTemplate): Promise<ApiResponse<boolean | ModuleTemplate>> {
    return super.lock(`/v1/template/modules/${moduleId}`, 'update', data && { ...data, isLocked: true });
  }

  public static unlockTemplate(moduleId: string, data?: ModuleTemplate): Promise<ApiResponse<boolean | ModuleTemplate>> {
    return super.lock(`/v1/template/modules/${moduleId}`, 'update', data && { ...data, isLocked: false });
  }

  public static moveTemplateForm(moduleId: string, request: MoveTemplateFormInModulesRequest): Promise<ApiResponse<boolean>> {
    return TemplateModuleServiceBase.put(`/v1/template/modules/${moduleId}/move-template-form`, request);
  }

  public static getShares(moduleId: string): Promise<ApiResponse<string[]>> {
    return TemplateModuleServiceBase.get(`/v1/template/modules/${moduleId}/shared-with`);
  }

  public static shareWithClient(moduleId: string, clientId: string): Promise<ApiResponse<boolean>> {
    return TemplateModuleService.post(`/v1/template/modules/${moduleId}/share-with/${clientId}`);
  }

  public static revokeSharingWithClient(moduleId: string, clientId: string): Promise<ApiResponse<boolean>> {
    return TemplateModuleService.post(`/v1/template/modules/${moduleId}/revoke-sharing/${clientId}`);
  }

  // #endregion
}
